// Body
$body-bg: #f8fafc;

$color-text: #151515;
$color-background: ghostwhite;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$color-primary: #0066d5;
$color-primary-highlight: #3c86d5;
