.devbar {
    height: 34px;
    width: 100%;


    text-align: center;
    font-weight: bold;
    font-size: 16pt;


    color: white;
    background-color: darkred;
}
