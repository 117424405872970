
* {
    outline: none !important;
}

*:focus {
    box-shadow: none !important;
}

p,ul,ol,h1,h2,h3,h4,label,table, .table, div {
    margin: 0;
    padding: 0;
}

.alert {
    margin: 0;
}

button, .btn {
    border: none;
    background: transparent;
}

a {
    &:hover {
        text-decoration: none;
    }
}
