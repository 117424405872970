// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
//@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;0,800;1,400&display=swap');

@import 'variables';
@import 'dev';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'reset';

body {
    background: url('/img/bg.jpeg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    color: $color-text;

    p, label {
        font-size: 14pt;
        margin-bottom: 0;
    }
}

.container {
    margin-top: 20px;
    max-width: 1000px;
}

.inner {
    padding: 30px;
    background-color: $color-background;
    opacity: 0.95;
}

.heading {
    h1 {
        font-size: 30pt;
        font-family: "Titillium Web","Source Sans Pro",sans-serif;
    }

}

.authentication-options {
    .authentication-option {
        padding: 10px;
        color: $color-background;
        background-color: $color-primary;

        &:hover, &.selected {
            background-color: $color-primary-highlight;
            cursor: pointer;
        }
    }
}

.status-checker {
    background-color: $color-primary;
    color: white;
    font-size: 16pt;
    text-align: center;
    padding: 10px;
}

.language-switcher {
    float:right;
    img {
        cursor: pointer;
        width: 50px;

        &:hover {
            opacity: 0.9;
        }
    }
}

.alert {
    border-radius: 0;
}

.footer {
    p {
        font-size: 10.5pt;
    }

    text-align: center;
}

.btn {
    border-radius: 0;
    border: 0;

    &-primary {
        background-color: $color-primary;
        color:white;
        &:hover {
            background-color: $color-primary-highlight;
            color:white;
        }
    }

    &-disabled {
        background-color: lightgray;
        cursor: not-allowed;
        &:hover {
            background-color: lightgray;
        }
    }
}

.text-right {
    text-align: right;
}
